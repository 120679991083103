import React from "react";
import "./Home.css";
import "boxicons";
import ProfilePhoto from "../img/david.jpg";

function Home() {
	return (
		<>
			<main className="l_main">
				<section className="home" id="home">
					<div className="home__container bd-grid">
						<h1 className="home__title">
							<span>HE</span>
							<br />
							LLO.
						</h1>

						<div className="home__scroll">
							<a href="#about" className="home__scroll-link">
								<box-icon
									name="up-arrow-alt"
									color="#fafaff"
								></box-icon>
								Watch more
							</a>
						</div>
						<img
							src={ProfilePhoto}
							alt="Dawid Binkowski"
							className="home__img"
						/>
					</div>
				</section>
			</main>
		</>
	);
}

export default Home;
