import { useEffect } from "react";
import ScrollReveal from "scrollreveal";

function Scroll() {
	const sr = ScrollReveal({
		origin: "top",
		distance: "80px",
		duration: 2000,
		reset: true
	});

	useEffect(() => {
		// SCROLL HOME
		sr.reveal(".home__title", {});
		sr.reveal(".home__scroll", { delay: 200 });
	});
}

export default Scroll;
