import React from "react";
import "./Portfolio.css";
import Work1 from "../img/thinqcraft.png";
import Work2 from "../img/space.png";
import Work3 from "../img/rezydencja.jpg";
import Work4 from "../img/agency.png";

function Portfolio() {
	return (
		<>
			<main className="l_main">
				<section className="portfolio section" id="portfolio">
					<h2 className="section-title">Portfolio</h2>
					<div className="portfolio__container bd-grid">
						<div className="portfolio__img">
							<img src={Work3} alt="portfolio rezydencja" />
							<div className="portfolio__link">
								<a
									target="_blank"
									rel="noreferrer"
									alt="Event house Rezydencja website in portfolio open in new tab on www"
									href="https://www.rezydencjawbialej.pl"
									className="portfolio__link-name live"
								>
									View live on www
								</a>
							</div>
						</div>
						<div className="portfolio__img">
							<img src={Work4} alt="portfolio agency website" />
							<div className="portfolio__link">
								<a
									target="_blank"
									rel="noreferrer"
									alt="Agency website in portfolio open in new tab on Vercel"
									href="https://agency-website-binkowskidawid.vercel.app/"
									className="portfolio__link-name live"
								>
									View live on Vercel
								</a>
								<a
									target="_blank"
									rel="noreferrer"
									alt="Agency website in portfolio open in new tab on GitHub"
									href="https://github.com/binkowskidawid/agency-website"
									className="portfolio__link-name github"
								>
									View code on GitHub
								</a>
							</div>
						</div>
						<div className="portfolio__img">
							<img src={Work1} alt="portfolio Thinqcraft" />
							<div className="portfolio__link">
								<a
									target="_blank"
									rel="noreferrer"
									alt="Thinqcraft website in portfolio open in new tab"
									href="https://thinqcraft.com"
									className="portfolio__link-name live"
								>
									View live on www
								</a>
							</div>
						</div>
						<div className="portfolio__img">
							<img src={Work2} alt="portfolio Space Tourism" />
							<div className="portfolio__link">
								<a
									target="_blank"
									rel="noreferrer"
									alt="Space Tourism App in portfolio open in new tab on Vercel"
									href="https://space-tourism-binkowskidawid.vercel.app/"
									className="portfolio__link-name live"
								>
									View live on Vercel
								</a>
								<a
									target="_blank"
									rel="noreferrer"
									alt="Space Tourism App in portfolio open in new tab on GitHub"
									href="https://github.com/binkowskidawid/space-tourism"
									className="portfolio__link-name github"
								>
									View code on GitHub
								</a>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
}

export default Portfolio;
