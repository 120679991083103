import React from "react";
import emailjs from "emailjs-com";
import "./Contact.css";

function Contact() {
	const initialValue = {
		name: "",
		email: "",
		message: ""
	};

	const handleInputName = (e) => {
		initialValue.name = e.target.value;
	};
	const handleInputEmail = (e) => {
		initialValue.email = e.target.value;
	};
	const handleInputMessage = (e) => {
		initialValue.message = e.target.value;
	};

	function sendEmail(e) {
		e.preventDefault();
		const { name, email, message } = initialValue;

		if (name && email && message !== "") {
			emailjs
				.sendForm(
					"Main Gmail account",
					"template_1pugmca",
					e.target,
					"user_gPBKbkBrG9PNDYFdutxZX"
				)
				.then(
					(result) => {
						alert(result.text);
					},
					(error) => {
						alert(error.text);
					}
				);
			e.target.reset();
		} else alert("Please fill out all the fields in the form");
	}

	return (
		<>
			<main className="l_main">
				<section className="contact section" id="contact">
					<h2 className="section-title">Contact</h2>
					<div className="contact__container bd-grid">
						<div className="contact__info">
							<h3 className="contact__subtitle">E-MAIL</h3>
							<a
								href="mailto:d.k.binkowski@gmail.com"
								className="contact__text"
								alt="email"
							>
								d.k.binkowski@gmail.com
							</a>
							<h3 className="contact__subtitle">PHONE</h3>
							<a
								href="tel:+48781089442"
								className="contact__text"
								alt="phone"
							>
								+48 781 089 442
							</a>
							<h3 className="contact__subtitle">ADDRESS</h3>
							<span className="contact__text">
								<a
									href="https://www.google.com/maps/place/Cz%C4%99stochowa/@50.8094537,18.9826866,11z/data=!3m1!4b1!4m5!3m4!1s0x4710b5c54e2ca8af:0x854f0d4d1a178236!8m2!3d50.8118195!4d19.1203094"
									alt="google maps"
									target="_blank"
									rel="noreferrer"
								>
									Częstochowa, Poland
								</a>
							</span>
						</div>
						<form
							onSubmit={sendEmail}
							action=""
							className="contact__form"
						>
							<div className="contact__inputs">
								<input
									onChange={handleInputName}
									type="text"
									placeholder="What's Your name?"
									className="contact__input"
									name="name"
								/>
								<input
									onChange={handleInputEmail}
									type="email"
									placeholder="Your email"
									className="contact__input"
									name="email"
								/>
							</div>
							<textarea
								onChange={handleInputMessage}
								id="message"
								placeholder="Write something and I'll read it with pleasure"
								cols="0"
								rows="10"
								className="contact__input"
								name="message"
							/>
							<input
								type="submit"
								value="Send"
								className="contact__button"
							/>
						</form>
					</div>
				</section>
			</main>
		</>
	);
}

export default Contact;
