import React from "react";
import "./Education.css";
import Books from "../img/books.png";

function Education() {
	return (
		<>
			<main className="l_main">
				<section className="education section" id="education">
					<h2 className="section-title">Education</h2>
					<div className="education__container bd-grid">
						<div>
							<p className="education__text">
								I am a self-taught programmer with strong
								self-denial to learn new things and
								technologies. I take up coding quite recently
								and I'm into it all of me. I'm spending as much
								time as possible to learning <strong>HTML, CSS,
								JavaScript, React and Next.js.</strong> Until recently, I treated coding as
								a hobby, but I briskly figured out I wanted it
								to be my job.
							</p>
						</div>
						<div className="education__img">
							<img src={Books} alt="books" />
						</div>
					</div>
				</section>
			</main>
		</>
	);
}

export default Education;
