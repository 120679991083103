import React from "react";
import "./Skills.css";
import Skill from "../img/skill.PNG";

function Skills() {
	return (
		<>
			<main className="l_main">
				<section className="skills section" id="skills">
					<h2 className="section-title">Skills</h2>
					<div className="skills__container bd-grid">
						<div className="skills__img">
							<img src={Skill} alt="skill" />
						</div>
						<div className="skills__box">
							<h3 className="skills__subtitle">Front-end</h3>
							<span className="skills__name html">HTML5</span>
							<span className="skills__name css">CSS</span>
							<span className="skills__name sass">SASS</span>
							<span className="skills__name tailwindcss">TailwindCSS</span>
							<span className="skills__name bootstrap">Bootstrap</span>
							<span className="skills__name bulma">Bulma</span>
							<span className="skills__name react">React</span>
							<span className="skills__name next">Next.js</span>
							<h3 className="skills__subtitle">Design</h3>
							<span className="skills__name figma">Figma</span>
							<span className="skills__name adobexd">AdobeXD</span>
							<h3 className="skills__subtitle">Programming</h3>
							<span className="skills__name js">JavaScript</span>
							<span className="skills__name ts">TypeScript</span>
							<h3 className="skills__subtitle">API</h3>
							<span className="skills__name graphql">GraphQL</span>
							<span className="skills__name rest">REST</span>
							<h3 className="skills__subtitle">DevOps</h3>
							<span className="skills__name docker">Docker</span>
							<h3 className="skills__subtitle">VCS</h3>
							<span className="skills__name git">Git</span>
							<span className="skills__name github">GitHub</span>
							<span className="skills__name gitlab">GitLab</span>
						</div>
					</div>
				</section>
			</main>
		</>
	);
}

export default Skills;
