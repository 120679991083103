import React from "react";
import "./About.css";
import "boxicons";
import AboutPhoto from "../img/about.JPG";

function About() {
	return (
		<>
			<main className="l_main">
				<section className="about section" id="about">
					<h2 className="section-title">About</h2>
					<div className="about__container bd-grid">
						<div className="about__img">
							<img src={AboutPhoto} alt="David Binkowski" />
						</div>
						<div>
							<h2 className="about__subtitle">Hi, I'm Dawid</h2>
							<span className="about__profession">
								<strong>Freelancer / Front-end Developer / React Developer</strong>
							</span>
							<p className="about__text">
								Hello. I'm glad you found this page. You can
								find my works on it, as well as find out what I
								can do and what still cannot. If you are want to
								be in touch with me, please visit the Contact
								section. <strong>Thanks and Regards.</strong>
							</p>
							<div className="about__social">
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.linkedin.com/in/dawid-binkowski-webdev"
									className="about__social-icon"
									alt="LinkedIn"
									title="LinkedIn"
								>
									<box-icon
										type="logo"
										name="linkedin"
										size="md"
										color="#3e33bd"
										animation="tada-hover"
									></box-icon>
								</a>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.codewars.com/users/binkowskidawid"
									className="about__social-icon"
									alt="CodeWars"
									title="CodeWars"
								>
									<box-icon
										name="code-alt"
										type="logo"
										size="md"
										color="red"
										animation="tada-hover"
									></box-icon>
								</a>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://github.com/binkowskidawid"
									className="about__social-icon"
									alt="GitHub"
									title="GitHub"
								>
									<box-icon
										type="logo"
										name="github"
										size="md"
										animation="tada-hover"
									></box-icon>
								</a>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
}

export default About;
