import React, { useState } from "react";
import "./Header.css";
import "boxicons";

function Header() {
	const [openMenu, setOpenMenu] = useState(false);
	const handleClickOnMobileMenuIcon = () => setOpenMenu(!openMenu);
	const closeMobileMenu = () => setOpenMenu(false);

	return (
		<>
			<header className="l-header">
				<nav className="nav bd-grid">
					<div>
						<a href="/" className="nav__logo">
							Dawid Binkowski
						</a>
					</div>
					<div
						className={
							openMenu ? "nav__menu show__menu" : "nav__menu"
						}
						id="nav-menu"
					>
						<ul className="nav__list">
							<li className="nav__item">
								<a
									href="#home"
									className="nav__link"
									onClick={closeMobileMenu}
								>
									Home
								</a>
							</li>
							<li className="nav__item">
								<a
									href="#about"
									className="nav__link"
									onClick={closeMobileMenu}
								>
									About
								</a>
							</li>
							<li className="nav__item">
								<a
									href="#education"
									className="nav__link"
									onClick={closeMobileMenu}
								>
									Education
								</a>
							</li>
							<li className="nav__item">
								<a
									href="#skills"
									className="nav__link"
									onClick={closeMobileMenu}
								>
									Skills
								</a>
							</li>
							<li className="nav__item">
								<a
									href="#portfolio"
									className="nav__link"
									onClick={closeMobileMenu}
								>
									Portfolio
								</a>
							</li>
							<li className="nav__item">
								<a
									href="#contact"
									className="nav__link"
									onClick={closeMobileMenu}
								>
									Contact
								</a>
							</li>
						</ul>
					</div>
					<div className="nav__toggle" id="nav-toggle">
						<box-icon
							id="menu-icon"
							name={openMenu ? "right-indent" : "menu"}
							color="#fafaff"
							onClick={handleClickOnMobileMenuIcon}
						></box-icon>
					</div>
				</nav>
			</header>
		</>
	);
}

export default Header;
