import React from "react";
import "./Footer.css";
import "boxicons";

function Footer() {
	const year = new Date().getFullYear();

	return (
		<>
			<footer className="footer section">
				<div className="footer__container bd-grid">
					<div className="footer__data">
						<h2 className="footer__title">&copy; Dawid Binkowski {year}</h2>
					</div>
					<div className="footer__data">
						<h2 className="footer__title">Watch</h2>
						<ul>
							<li>
								<a href="#home" className="footer__link">
									Home
								</a>
							</li>
							<li>
								<a href="#about" className="footer__link">
									About
								</a>
							</li>
							<li>
								<a href="#education" className="footer__link">
									Education
								</a>
							</li>
							<li>
								<a href="#skills" className="footer__link">
									Skills
								</a>
							</li>
							<li>
								<a href="#portfolio" className="footer__link">
									Portfolio
								</a>
							</li>
							<li>
								<a href="#contact" className="footer__link">
									Contact
								</a>
							</li>
						</ul>
					</div>
					<div className="footer__data">
						<h2 className="footer__title">Check this</h2>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.linkedin.com/in/dawid-binkowski-webdev"
							className="footer_social"
							alt="LinkedIn"
							title="LinkedIn"
						>
							<box-icon
								type="logo"
								name="linkedin"
								size="md"
								color="#3e33bd"
								animation="tada-hover"
							></box-icon>
						</a>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.codewars.com/users/binkowskidawid"
							className="footer_social"
							alt="CodeWars"
							title="CodeWars"
						>
							<box-icon
								name="code-alt"
								type="logo"
								size="md"
								color="red"
								animation="tada-hover"
							></box-icon>
						</a>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://github.com/binkowskidawid"
							className="footer_social"
							alt="GitHub"
							title="GitHub"
						>
							<box-icon
								type="logo"
								name="github"
								color="#697477"
								size="md"
								animation="tada-hover"
							></box-icon>
						</a>
					</div>
				</div>
			</footer>
		</>
	);
}

export default Footer;
