import React from "react";
import "./FiverrButton.css";

function FiverrButton() {
	return (
		<>
			<button className="fiverr">
				<a
					href="https://www.fiverr.com/dawid_binkowski"
					target="_blank"
					rel="noreferrer"
					alt="My offer on fiverr.com website"
				>
					CHECK MY OFFER
				</a>
			</button>
		</>
	);
}

export default FiverrButton;
