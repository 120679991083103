import React from "react";
import "./App.css";
import Scroll from "./components/Scroll";
import FiverrButton from "./components/FiverrButton";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Education from "./components/Education";
import Skills from "./components/Skills";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
	Scroll();
	return (
		<div className="app">
			<FiverrButton/>
			<Header />
			<Home />
			<About />
			<Education />
			<Skills />
			<Portfolio />
			<Contact />
			<Footer />
		</div>
	);
}

export default App;
